<template>
  <div class="login-container" data-flex="main:center cross:center dir:top">
    <div class="login-weaper mt-20">
      <div class="login-logo" data-flex="main:center cross:center">
        <img src="@/assets/img/logo-2.png" />
      </div>
      <div class="login-header">礼一后台管理系统</div>
     <lz-form
        :form="form"
        :col="1"
        @keyup-enter="handleSubmit"
        size="medium">
      </lz-form>
      <el-button
        :loading="loading"
        :disabled="disabled"
        type="primary"
        class="w100"
        size="medium"
        @click="handleSubmit">
        登录
      </el-button>
    </div>
    <div class="page-login--layer page-login--layer-area">
      <ul class="circles">
        <li v-for="n in 10" :key="n"></li>
      </ul>
    </div>
  </div>
</template>
<script>
import { formGetValue } from '@/utils/form';
import { setAccessToken } from '@/utils/auth';

export default {
  data() {
    return {
      loading: false,
      form: [
        {
          type: 'text',
          name: '用户名',
          fieldName: 'user_name',
          value: '',
          prefixIcon: 'ri-user-line',
        },
        {
          type: 'password',
          name: '密码',
          fieldName: 'password',
          value: '',
          prefixIcon: 'ri-lock-password-line',
        },
      ],
    };
  },
  computed: {
    disabled() {
      return this.form.some((el) => el.value === '');
    },
  },
  methods: {
    async handleSubmit() {
      if (this.disabled || this.loading) return;
      const { params } = formGetValue(this.form);
      this.loading = true;
      const response = await this.$baseHttp.post('/admin/login', params);
      this.loading = false;
      if (!response) return;
      setAccessToken(response.data.token);
      const reffer = this.$route.query.callback;
      this.$router.push({ path: reffer || '/' });
    },
  },
};
</script>
<style lang="scss" scoped>
.login-container {
  width: 100%;
  height: 100vh;
  background: url('../../assets/img/login.png') 0 bottom repeat-x;
  -webkit-animation: cloud 200s linear infinite;
  animation: cloud 200s linear infinite;
  background-size: contain;
  @-webkit-keyframes cloud{
    0%{ background-position: 0 bottom;}
    100%{ background-position: -1920px bottom;}
  }
}

.circles {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  margin: 0px;
  padding: 0px;
  z-index: 0;
  li {
    position: absolute;
    display: block;
    list-style: none;
    width: 20px;
    height: 20px;
    background: rgba(230, 240, 246, .4);
    animation: animate 25s linear infinite;
    bottom: -200px;
    @keyframes animate {
      0%{
        transform: translateY(0) rotate(0deg);
        opacity: 1;
        border-radius: 0;
      }
      100%{
        transform: translateY(-1000px) rotate(720deg);
        opacity: 0;
        border-radius: 50%;
      }
    }
    &:nth-child(1) {
      left: 15%;
      width: 80px;
      height: 80px;
      animation-delay: 0s;
    }
    &:nth-child(2) {
      left: 5%;
      width: 20px;
      height: 20px;
      animation-delay: 2s;
      animation-duration: 12s;
    }
    &:nth-child(3) {
      left: 70%;
      width: 20px;
      height: 20px;
      animation-delay: 4s;
    }
    &:nth-child(4) {
      left: 40%;
      width: 60px;
      height: 60px;
      animation-delay: 0s;
      animation-duration: 18s;
    }
    &:nth-child(5) {
      left: 65%;
      width: 20px;
      height: 20px;
      animation-delay: 0s;
    }
    &:nth-child(6) {
      left: 75%;
      width: 150px;
      height: 150px;
      animation-delay: 3s;
    }
    &:nth-child(7) {
      left: 35%;
      width: 200px;
      height: 200px;
      animation-delay: 7s;
    }
    &:nth-child(8) {
      left: 50%;
      width: 25px;
      height: 25px;
      animation-delay: 15s;
      animation-duration: 45s;
    }
    &:nth-child(9) {
      left: 20%;
      width: 15px;
      height: 15px;
      animation-delay: 2s;
      animation-duration: 35s;
    }
    &:nth-child(10) {
      left: 85%;
      width: 150px;
      height: 150px;
      animation-delay: 0s;
      animation-duration: 11s;
    }
  }
}

.login-header {
  text-align: center;
  padding: 20px 0 50px;
  color: #666;
  font-size: 16px;
  letter-spacing: 3px;
  color: #80cbc4;
}

.login-logo {
  overflow: hidden;
  width: 110px;
  height: 110px;
  margin: -50px auto 20px auto;
  border-radius: 50%;
  -webkit-box-shadow: 0 4px 40px rgba(0, 0, 0, 0.07);
  box-shadow: 0 4px 40px rgba(0, 0, 0, 0.07);
  background-color: #fff;
  z-index: 1;
  position: relative;
  box-sizing: border-box;

  img {
    width: 100%;
    border-radius: 50%;
  }
}

.login-weaper {
  width: 350px;
  background: #fff;
  box-shadow: 0 7px 25px rgba(0, 0, 0, 0.08);
  background-color: #fff;
  border-radius: 3px;
  padding: 0 40px 40px;
  position: relative;
  z-index: 1;

  ::v-deep .el-form-item__label {
    display: none;
  }

  ::v-deep .el-input__inner {
    border: 0;
    border-radius: 0;
    border-bottom: 1px solid #DCDFE6;
  }

  ::v-deep .el-form-item__content {
    margin-right: 0;
  }
}

.login-submit {
  height: 45px;
  margin-top: 40px;
}
</style>
